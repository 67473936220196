'use client'
import { createContext } from 'react'

import type EventTracking from '@/services/tracking'

interface EventTrackingContextInterface {
  eventTracking: EventTracking
}

export const EventTrackingContext = createContext<EventTrackingContextInterface>({} as EventTrackingContextInterface)
