import _isEmpty from 'lodash/isEmpty'
import normalize from 'normalize-object'
export type HEADERS = { [key: string]: string }

export type PROBLEM_CODE =
  | 'CLIENT_ERROR'
  | 'SERVER_ERROR'
  | 'TIMEOUT_ERROR'
  | 'CONNECTION_ERROR'
  | 'NETWORK_ERROR'
  | 'UNKNOWN_ERROR'
  | 'CANCEL_ERROR'

export interface ApiErrorResponse<T> extends Response {
  ok: false
  // problem: PROBLEM_CODE

  data?: T
  // status?: number
  // statusText?: string
  // headers?: HEADERS
}

export interface ApiOkResponse<T> extends Response {
  ok: true
  // problem: null
  // originalError: null

  data?: T
  // status?: number
  // statusText?: string
  // headers?: HEADERS
}

export type ApiResponse<T, U = T> = ApiErrorResponse<U> | ApiOkResponse<T>
export type ApiProblemType =
  | 'timeout'
  | 'cannot-connect'
  | 'server'
  | 'unauthorized'
  | 'forbidden'
  | 'not-found'
  | 'rejected'
  | 'unknown'
// | 'bad-data'

export type GeneralApiProblem =
  | { kind: ApiProblemType; error: unknown; statusCode: number; statusText: string }
  | { kind: 'bad-data'; error: string }
// /**
//  * Times up.
//  */
// | { kind: 'timeout'; reason?: string }
// /**
//  * Cannot connect to the server for some reason.
//  */
// | { kind: 'cannot-connect'; reason?: string }
// /**
//  * The server experienced a problem. Any 5xx error.
//  */
// | { kind: 'server'; reason?: string }
// /**
//  * We're not allowed because we haven't identified ourself. This is 401.
//  */
// | { kind: 'unauthorized'; reason?: string }
// /**
//  * We don't have access to perform that request. This is 403.
//  */
// | { kind: 'forbidden'; reason?: string }
// /**
//  * Unable to find that resource.  This is a 404.
//  */
// | { kind: 'not-found'; reason?: string }
// /**
//  * All other 4xx series errors.
//  */
// | { kind: 'rejected'; reason?: string }
// /**
//  * Something truly unexpected happened. Most likely can try again. This is a catch all.
//  */
// | { kind: 'unknown'; reason?: string }
// /**
//  * The data we received is not in the expected format.
//  */
// | { kind: 'bad-data'; reason?: string }

function getJsonFromText(str: string): unknown | null {
  let obj = null
  try {
    obj = JSON.parse(str)
    obj = normalize(obj, 'camel')
  } catch (e) {
    return null
  }
  return obj
}

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export async function getGeneralApiProblem(
  response: ApiResponse<unknown>,
  url?: string | null,
): Promise<GeneralApiProblem | null> {
  // console.log('=======================')
  // console.log(`getGeneralApiProblem response.statusText`, response.statusText)
  // console.log('=======================')

  const { status, statusText } = response

  switch (response.statusText) {
    case 'CONNECTION_ERROR':
      return { kind: 'cannot-connect', statusCode: status, statusText, error: null }
    case 'NETWORK_ERROR':
      return { kind: 'cannot-connect', statusCode: status, statusText, error: null }
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', statusCode: status, statusText, error: null }
    case 'SERVER_ERROR':
      return { kind: 'server', statusCode: status, statusText, error: null }
    case 'UNKNOWN_ERROR':
      return { kind: 'unknown', statusCode: status, statusText, error: null }
    // case 'CLIENT_ERROR':
    case 'CANCEL_ERROR':
      return null
  }

  const errorsText = await response.text()
  const reason = getJsonFromText(errorsText)

  console.log(`getGeneralApiProblem response.text() ${url}`, status, errorsText)

  switch (status) {
    case 401:
      return { kind: 'unauthorized', statusCode: status, statusText, error: reason }
    case 403:
      return { kind: 'forbidden', statusCode: status, statusText, error: reason }
    case 404:
      return { kind: 'not-found', statusCode: status, statusText, error: reason }
    case 422:
      return { kind: 'rejected', statusCode: status, statusText, error: reason }
    default:
      return { kind: 'unknown', statusCode: status, statusText, error: reason }
  }
}

export class ApiError extends Error {
  error: unknown
  statusCode: number
  statusText: string

  constructor(message: string, error: unknown, statusCode: number, statusText: string) {
    if (error && typeof error === 'object' && 'message' in error) {
      message = JSON.stringify(error.message)
    }

    super(message) // (1)
    this.name = 'ApiError' // (2)
    this.error = error
    this.statusCode = statusCode
    this.statusText = statusText
  }

  getValidationErrors() {
    if (this.error && typeof this.error === 'object' && 'errors' in this.error && !_isEmpty(this.error.errors)) {
      const errors: Record<string, string> = {}
      // @ts-expect-error error is unknow
      for (const [key, value] of Object.entries(this.error.errors)) {
        errors[key] = value && Array.isArray(value) ? value[0] : value
      }

      if (!_isEmpty) {
        return errors
      }
    }

    return null
  }
}
